<footer>
  <div class="row r1">
    <div class="nhs">
      <img
        src="https://assets-global.website-files.com/5d27595e2836ca3889cdbc80/63242bced6ba2bd3198c5a06_nhs-services.svg"
        loading="lazy"
        alt="Providing NHS services"
      />
      <a
        href="https://www.pharmacyregulation.org/registers/pharmacy/registrationnumber/"
        target="_blank"
        ><img
          src="https://assets-global.website-files.com/5d27595e2836ca3889cdbc80/5dfccca8305f51a8b9ddd801_registeredpharmacy-9011121.png"
          loading="lazy"
          alt="Registered Pharmacy 9011121"
      /></a>
    </div>
    <div class="col c1">
      <img
        class="logo"
        style="cursor: pointer"
        src="/assets/images/app-logo.png"
        alt=""
      />
      <!-- <p>
        OurStudio is a digital agency UI / UX Design and Website Development
        located in Ohio, United States of America
      </p> -->
      <div class="box">
        <a
          href="https://www.instagram.com/"
          target="_blank"
          class="link-instagram"
        >
          <img
            src="https://assets-global.website-files.com/5d27595e2836ca3889cdbc80/632c4151fec8b27b8730672b_link_icon-instagram.svg"
            loading="lazy"
            alt="Instagram"
            class="social-icon-footer"
          />
        </a>
        <a
          href="https://www.facebook.com/"
          target="_blank"
          class="link-facebook"
        >
          <img
            src="https://assets-global.website-files.com/5d27595e2836ca3889cdbc80/632c41304fe92113ecd13814_link_icon-facebook.svg"
            loading="lazy"
            alt="Facebook"
            class="social-icon-footer"
          />
        </a>
        <a href="https://twitter.com/" target="_blank" class="link-twitter">
          <img
            src="https://assets-global.website-files.com/5d27595e2836ca3889cdbc80/64fef83629bc3c9db59cca3f_TwitterXIconBlue.svg"
            loading="lazy"
            alt="Twitter"
            class="social-icon-footer"
          />
        </a>
      </div>
    </div>

    <div class="col c2">
      <h2>Services</h2>
      <p routerLink="/">Home</p>
      <p routerLink="/about">About</p>
      <p routerLink="/contact-us">Contact Us</p>
      <p routerLink="/how-it-works">How it works</p>
      <p routerLink="/testimonials">Testimonials</p>
    </div>
    <div class="col c2">
      <h2>Company</h2>
      <p routerLink="/help">Help</p>
      <p routerLink="/join-us">Join us</p>
      <p routerLink="/place-order">Place Order</p>
    </div>
    <div class="col c3">
      <h2>Certified</h2>
      <p><strong>One Dose Ltd</strong></p>
      <p class="cert">
        Pharmacy Address <br />
        11 Rowan Trade Park Bradford BD4 8TQ <br />
        GPhC number: 9010381 <br />
        Company registered in England & Wales, number 09890559 <br />
        Registered office: Trust House, St James Business Park, 5 New Augustus
        Street, Bradford, West Yorkshire, England, BD1 5LL
      </p>
      <p style="margin-top: 10px"><strong>Superintendent pharmacist</strong></p>
      <p class="cert">
        Sajid Khan MPharm <br />
        GPhC number: 2055437
      </p>
    </div>

    <!-- <hr /> -->
    <svg
      class="line"
      width="90%"
      height="2"
      viewBox="0 0 1232 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style="margin-top: 20px"
    >
      <path d="M0 1L1232 1" stroke="#4C94CE" />
    </svg>

    <div class="btmText">
      <div class="col1">
        <p>Copyright repeatrx</p>
      </div>
      <div class="col2">
        <div class="coll">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 2C15.87 2 19 5.13 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9C5 5.13 8.13 2 12 2ZM7 9C7 11.85 9.92 16.21 12 18.88C14.12 16.19 17 11.88 17 9C17 6.24 14.76 4 12 4C9.24 4 7 6.24 7 9ZM12 11.5C10.6193 11.5 9.5 10.3807 9.5 9C9.5 7.61929 10.6193 6.5 12 6.5C13.3807 6.5 14.5 7.61929 14.5 9C14.5 10.3807 13.3807 11.5 12 11.5Z"
              fill="#4B59C3"
            />
          </svg>
          <a href="https://www.google.com/maps?q=11+Rowan+Trade+Park,+Bradford,+BD4+8TQ" target="_blank"><p>11 Rowan Trade Park, Bradford, BD4 8TQ</p></a>
        </div>
        <div class="coll">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
              fill="#4B59C3"
            />
          </svg>
          <p>rx@repeatrx.com</p>
        </div>
        <div class="coll">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.33 14.9 16.22 14.89 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3Z"
              fill="#4B59C3"
            />
          </svg>
          <p>08000 888 4999</p>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper">
    <div class="content-wrapper">
      <div class="new-h4">
        <strong>Download the Repeaterx app</strong>, switch to RepeaterX and
        enjoy an enhanced experience
      </div>
    </div>
    <div class="buttons-wrapper">
      <a
        href="https://apps.apple.com/gb/app/"
        target="_blank"
        class="link-block-appstore footer-cta w-inline-block"
      >
        <img
          src="https://assets-global.website-files.com/5d27595e2836ca3889cdbc80/63219f50e02ba135f97d8c05_Apple-App-Store-Button.png"
          loading="lazy"
          alt="Link to download app from Apple Store"
          class="app-icon-footer"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/"
        target="_blank"
        class="link-block-googleplay w-inline-block"
      >
        <img
          src="https://assets-global.website-files.com/5d27595e2836ca3889cdbc80/63219f66ddd87d700faa4c8d_google-store-button.png"
          loading="lazy"
          alt="Link to download app from Google Play store"
          class="app-icon-footer"
        />
      </a>
    </div>
  </div>
</footer>
