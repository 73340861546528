import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ApiService } from '../../../services/api.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { ConstantsService } from 'src/app/services/constants.service';
import { Resp } from 'src/app/interfaces/response';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: false, autoClose: true },
    },
  ],
})
export class HeaderComponent implements OnInit {
  isCollapsed = true;
  carSubscribe: any;
  title: '';
  categoriesList: any = [];
  isAuthenticated = false;
  isAdmin = false;
  isBuyer = false;
  isSeller = false;
  isNavbarOpen = false;
  isTrue = false;
  isHumburgerActive = false;
  isSearchOpen = false;
  languages = this.cs.LANGUAGES;
  lang: any;
  userType = '';
  constructor(
    public api: ApiService,
    public cs: ConstantsService,
    public router: Router,
    private route: ActivatedRoute,
    public renderer2: Renderer2,
    public ts: TranslateService,
    private el: ElementRef
  ) {
    this.ts.currentLang = this.api.getCurrentLang();
    api.userLoggedInObs.subscribe((m) => {
      this.isAuthenticated = m;
      if (this.isAuthenticated) {
        this.loginUpdates();
      }
    });

    this.lang = this.api.translate('website.header');
    this.lang.subscribe((d: any) => {
      this.lang = d;
    });
  }
  categoryHeadVisible = false;
  hovering = false;

  setHovering(value: boolean) {
    this.hovering = value;
  }
  loginUpdates(): void {}

  ngOnInit() {}

  logOut(): void {
    this.api.logOutSession().subscribe((resp: any) => {
      const check = this.api.logOut();
      if (check) {
        location.reload();
      }
    });
  }

  toggleMenu() {
    var navLinks = document.getElementById('navLinks');
    navLinks.classList.toggle('show');
  }
}
