<div class="navbar">
  <div class="top-nav">
    <img
      style="cursor: pointer"
      class="logo"
      src="/assets/images/logo1.png"
      alt="logo"
      routerLink="/"
    />
    <!-- <div class="btns" id="navButtons">
      <button class="btn" id="home">Home</button>
      <button class="btn" id="about">About</button>
      <button class="btn" id="help">Help and Contact</button>
      <button class="btn" id="how-it-works">How Repeaterx Works</button>
    </div>
    <div class="btns" id="loginButton">
      <button class="btn" routerLink="/login" *ngIf="api.isLogin() === false">
        Login
      </button>
    </div> -->
    <div class="menu-icon" (click)="toggleMenu()">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
    <div class="nav-links" id="navLinks">
      <a class="link" routerLink="/">Home</a>
      <a class="link" href="#about">About</a>
      <a class="link" routerLink="/contact-us" >Contact Us</a>
      <a class="link" href="#how-it-works">How Repeaterx works</a>
      <a class="link" routerLink="/login" *ngIf="api.isLogin() === false"
        >Login</a
      >
      <a
        *ngIf="api.isAuthenticated()"
        routerLink="/{{ api.user.user_type.toLowerCase() }}/dashboard"
        class="link"
      >
        My Portal
      </a>
    </div>
  </div>
</div>
