import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConstantsService } from './constants.service';
import { map } from 'rxjs/operators';
import { apis } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string = '';
  dataStatusSubscribe = 'fetching';
  userLoggedInSource = new BehaviorSubject(false);
  scrollBottom: boolean;
  scrollBottomChange = new Subject<boolean>();
  userImage = new Subject<string>();
  notification = new Subject<string>();
  userLoggedInObs = this.userLoggedInSource.asObservable();
  user: User;
  categoriesList: any = [];
  subsPackageList: any = [];

  constructor(
    public http: HttpClient,
    public cs: ConstantsService,
    private ts: TranslateService
  ) {
    this.baseUrl = apis.baseUrl + '/public';
    this.scrollBottom = false;
    this.scrollBottomChange.subscribe((value) => {
      this.scrollBottom = value;
    });
    if (localStorage.getItem('token')) {
      this.user = JSON.parse(localStorage.getItem('user') as '');
      this.userLoggedInSource.next(true);
    } else {
      this.userLoggedInSource.next(false);
    }
  }

  toggleScrollBottom(value: boolean): void {
    this.scrollBottomChange.next(value);
  }

  login(params: any): Observable<any> {
    const url = `${this.baseUrl}/login`;

    return this.http.post<any>(url, params).pipe(
      map((resp) => {
        if (resp && resp.success && resp.data.token) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('user', JSON.stringify(resp.data));
          this.user = resp.data;
          // console.log(this.user)
          this.userLoggedInSource.next(true);
        }

        return resp;
      })
    );
  }
  nshLogin(): Observable<any> {
    const url = `${this.baseUrl}/nhs-login`;
    return this.http.get<any>(url)
    
  }

  googleLogin(): Observable<any> {
    const url = `${this.baseUrl}/login/${'google'}`;

    return this.http.get<any>(url).pipe(
      map((resp) => {
        if (resp && resp.success && resp.data.token) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('user', JSON.stringify(resp.data));
          this.user = resp.data;
          // console.log(this.user)
          this.userLoggedInSource.next(true);
        }

        return resp;
      })
    );
  }

  register(params: any): Observable<any> {
    const url = `${this.baseUrl}/register`;

    return this.http.post<any>(url, params).pipe(
      map((resp) => {
        if (resp && resp.success && resp.data.token) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('user', JSON.stringify(resp.data));
          this.user = resp.data;
          this.userLoggedInSource.next(true);
        }

        return resp;
      })
    );
  }

  doUserRedirects(resp: any, router: Router) {
    console.log('resp.data.userType', resp.data.user_type);

    switch (resp.data.user_type) {
      case ConstantsService.USER_ROLES.ADMIN || 'Admin': {
        router.navigate(['/admin/dashboard']);
        break;
      }
      case ConstantsService.USER_ROLES.PATIENT || 'Patient': {
        router.navigate(['/patient/dashboard']);
        break;
      }
    }
  }

  logOut(): boolean {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.user.id = 0;
    this.userLoggedInSource.next(false);

    return true;
  }

  logOutSession(): Observable<any> {
    const url = `${this.baseUrl}/logout`;

    return this.http.post<any>(url, {});
  }

  checkPermission(permission: string): boolean {
    // const checkUrserRole = this.user.userroles.findIndex(r => r.role.name === ConstantsService.ADMIN)

    // if (checkUrserRole > -1) {
    //     return true
    // }

    const index = this.user.UserPermissions.findIndex(
      (item: any) => item.Permission.title === permission
    );
    // console.log('asdsad',index,permission,this.user.permissions[index]);

    if (index > -1) {
      return true;
    }

    return false;
  }

  checkPermissions(permissions: Array<string>): boolean {
    // const checkUrserRole = this.user.userroles.findIndex(r => r.role.name === ConstantsService.ADMIN)

    // if (checkUrserRole > -1) {
    //     return true
    // }

    // console.log('permissions', permissions)

    if (permissions[0] == '') {
      return true;
    }

    const index = this.user.UserPermissions.findIndex(
      (item: any) => permissions.indexOf(item.Permission.title) > -1
    );
    if (index > -1) {
      return true;
    }

    return false;
  }

  checkRole() {
    return true;
    // const checkUrserRole = this.user.userroles.findIndex(r => r.role.name === ConstantsService.ADMIN)

    // if (checkUrserRole > -1) {
    //     return true
    // }

    // return false
  }

  isAuthenticated(): boolean {
    if (localStorage.getItem('token')) {
      return true;
    }

    return false;
  }
  isLogin(): boolean {
    if (!localStorage.getItem('token')) {
      return false;
    }

    return true;
  }

  isAdmin(): boolean {
    if (
      this.isAuthenticated() &&
      this.user.user_type === ConstantsService.ADMIN
    ) {
      return true;
    }

    return false;
  }
  isBuyer(): boolean {
    if (
      this.isAuthenticated() &&
      this.user.user_type === ConstantsService.BUYER
    ) {
      return true;
    }

    return false;
  }
  isLogistics(): boolean {
    if (
      this.isAuthenticated() &&
      this.user.user_type === ConstantsService.LOGISTICS
    ) {
      return true;
    }

    return false;
  }
  isSeller(): boolean {
    if (
      this.isAuthenticated() &&
      this.user.user_type === ConstantsService.SELLER
    ) {
      return true;
    }

    return false;
  }

  jsonToFormData(
    jsonObject: any,
    parentKey?: any,
    carryFormData?: FormData
  ): FormData {
    const formData = carryFormData || new FormData();
    let index = 0;

    // tslint:disable-next-line: forin
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] !== null && jsonObject[key] !== undefined) {
          let propName = parentKey || key;
          if (parentKey && this.isObject(jsonObject)) {
            propName = parentKey + '[' + key + ']';
          }
          if (parentKey && this.isArray(jsonObject)) {
            propName = parentKey + '[' + index + ']';
          }
          if (jsonObject[key] instanceof File) {
            formData.append(propName, jsonObject[key]);
          } else if (jsonObject[key] instanceof FileList) {
            for (let j = 0; j < jsonObject[key].length; j++) {
              formData.append(
                propName + '[' + j + ']',
                jsonObject[key].item(j)
              );
            }
          } else if (
            this.isArray(jsonObject[key]) ||
            this.isObject(jsonObject[key])
          ) {
            this.jsonToFormData(jsonObject[key], propName, formData);
          } else if (typeof jsonObject[key] === 'boolean') {
            formData.append(propName, +jsonObject[key] ? '1' : '0');
          } else {
            formData.append(propName, jsonObject[key]);
          }
        }
      }
      index++;
    }

    return formData;
  }

  isArray(val: any) {
    const toString = {}.toString;

    return toString.call(val) === '[object Array]';
  }

  isObject(val: any) {
    return !this.isArray(val) && typeof val === 'object' && !!val;
  }

  checkVerificationCode(data: any): Observable<any> {
    const url = `${apis.baseUrl}/public/verify-email`;

    return this.http.post<any>(url, data);
  }

  resendVerificationCode(data: any): Observable<any> {
    const url = `${apis.baseUrl}/public/resend-code`;

    return this.http.post<any>(url, data);
  }

  checkVerifyCode(data: any): Observable<any> {
    const url = `${this.baseUrl}/verify-code`;

    return this.http.post<any>(url, data);
  }
  getImage(id: any): any {
    const url = `${apis.baseUrl}/public/magazine/image?url=${id}`;
    return url;
  }

  resetPass(data: any): Observable<any> {
    const url = `${this.baseUrl}/reset-password`;

    return this.http.post<any>(url, data);
  }

  translate(key: string) {
    return this.ts.get(key);
  }

  getCurrentLang() {
    let lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : this.ts.getBrowserLang();
    if (!lang) {
      lang = 'en';
    }
    return lang;
  }

  changeLanguage(lang: string) {
    this.ts.currentLang = lang;
    localStorage.setItem('lang', lang);
    this.ts.setDefaultLang(lang);
    this.ts.use(lang);
    location.reload();
  }

  userImageUrl(userId?: number) {
    userId = userId ? userId : -1;

    return `${apis.baseUrl}/public/profile-image/${userId}`;
  }
}
